<template>
  <el-row>
    <el-col :span="8">
      <el-select
        style="width: 95%"
        v-model="value.subjectType"
        disabled
        @change="subjectTypeChange"
      >
        <!-- :disabled="value.subjectType === '1'" -->
        <el-option key="1" label="企业" value="1"></el-option>
        <el-option key="2" label="个人" value="2"></el-option>
      </el-select>
    </el-col>
    <el-col :span="16">
      <div v-if="value.subjectType === '2'">
        <el-select
          v-model="value.subjectAccount"
          filterable
          :placeholder="'请输入关键字'"
          style="width: 100%"
          @change="inputChange"
          remote
          reserve-keyword
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label + '-' + item.username"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div v-if="value.subjectType === '1'">
        <el-select
          v-model="value.subjectAccount"
          filterable
          :placeholder="'请输入关键字'"
          style="width: 100%"
          @change="inputChange1"
        >
          <el-option
            v-for="item in optionsList"
            :key="item.value"
            :label="item.label + '-' + item.idCardNumber"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <!-- <div v-if="value.subjectType === '1'">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.enterpriseName + '-' + item.adminName + '-' + item.adminIdCardNo"
            :value="item.value"
          ></el-option>
        </div>
        <div v-else>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label + '-' + item.idCardNumber"
            :value="item.value"
          ></el-option>
      </div>-->
    </el-col>
  </el-row>
</template>

<script>
import { api } from '/src/api/base';
export const UserSimpleList = api('/user')('user.simple.list.json');
// export const EnterpriseAdminList = api('')(
//   'enterprise.admin.list.json'
// );
export const EnterpriseSimpleList = api('/enterprise')(
  'enterprise.simple.list.json'
);
export default {
  props: {
    value: Object // key code,
  },
  data () {
    return {
      options: [],
      optionsList: []
    };
  },
  mounted () {
    this.remoteMethod(this.value.subjectName);
    this.getEnterpriseList();

  },
  methods: {
    subjectTypeChange () {
      this.value.subjectName = null;
      this.value.subjectAccount = null;
      this.options = [];
      this.$emit('change', this.value);
    },
    inputChange1 (val) {

      if (this.optionsList.find(it => it.value === val)) {
        this.value.subjectName = this.optionsList.find(
          it => it.value === val
        ).label;
      }
      if (!val) {
        this.optionsList = [];
      }

      this.$emit('change', this.value);
    },
    inputChange (val) {
      if (this.options.find(it => it.value === val)) {
        this.value.subjectName = this.options.find(
          it => it.value === val
        ).label;
      }
      if (!val) {
        this.options = [];
      }
      console.log(this.value, 111);
      this.$emit('change', this.value);
    },
    async remoteMethod (keywords) {
      if (keywords === '') {
        this.options = [];
        return;
      }
      if (this.value.subjectType === '1') {
        this.getEnterpriseList(keywords);
      }
      if (this.value.subjectType === '2') {
        this.getUserList(keywords);
      }
    },
    async getUserList (keywords) {
      const users = await UserSimpleList({
        pageNumber: 1,
        pageSize: 10,
        keywords,
        isAuthenticated: true,

        $ignoreRepeat: true
      });
      this.options = users.list.map(it => {
        return {
          ...it,
          label: it.name,
          value: it.code
        };
      });
    },
    async getEnterpriseList () {
      // const enterprises = await EnterpriseAdminList({
      //   pageNumber: 1,
      //   pageSize: 10,
      //   keywords,
      //   // authenticateState: '3',
      //   $ignoreRepeat: true
      // });

      // this.options = enterprises.map(it => {
      //   return {
      //     ...it,
      //     label: it.adminName,
      //     value: it.adminCode
      //   };
      // });
      const enterprises = await EnterpriseSimpleList({
        pageNumber: 1,
        pageSize: 10,
        code: window.localStorage.getItem('enterprise'),
        authenticateState: '3',
        $ignoreRepeat: true
      });
      this.optionsList = enterprises.list.map(it => {
        return {
          ...it,
          label: it.name,
          value: it.code
        };
      });
    }
  }
};
</script>

<style></style>
